import React, { createContext, useContext, useState, useEffect, ReactNode } from 'react';

interface UserConfig {
  theme: string;
  isRTL: boolean;
}

interface AuthContextType {
  isAuthenticated: boolean;
  loading: boolean;
  login: (username: string, password: string) => void;
  logout: () => void;
  config: UserConfig;
  userRole: string;
}

const AuthContext = createContext<AuthContextType | undefined>(undefined);

export const AuthProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
  const [isAuthenticated, setIsAuthenticated] = useState<boolean>(
    () => !!localStorage.getItem('isAuthenticated')
  );
  const [loading, setLoading] = useState<boolean>(true);
  const [userRole, setUserRole] = useState<string>(() => localStorage.getItem('userRole') || '');

  const [config] = useState<UserConfig>({
    theme: 'light',
    isRTL: false
  });

  const login = (username: string, password: string) => {
    if (username === 'dev@ipercom.com' && password === 'devipercom') {
      setIsAuthenticated(true);
      setUserRole('revendeur');
      localStorage.setItem('isAuthenticated', 'true');
      localStorage.setItem('userRole', 'revendeur');
    } else if (username === 'editor@ipercom.com' && password === 'editoripercom') {
      setIsAuthenticated(true);
      setUserRole('editeur');
      localStorage.setItem('isAuthenticated', 'true');
      localStorage.setItem('userRole', 'editeur');
    } else if (username === 'ipbx@ipercom.com' && password === 'ipbxipercom') {
      setIsAuthenticated(true);
      setUserRole('ipbx');
      localStorage.setItem('isAuthenticated', 'true');
      localStorage.setItem('userRole', 'ipbx');
    }
  };

  const logout = () => {
    setIsAuthenticated(false);
    setUserRole('');
    localStorage.removeItem('isAuthenticated');
    localStorage.removeItem('userRole');
  };

  useEffect(() => {
    setLoading(false);
  }, []);

  return (
    <AuthContext.Provider
      value={{ isAuthenticated, loading, login, logout, config, userRole }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export const useAuthContext = (): AuthContextType => {
  const context = useContext(AuthContext);
  if (!context) {
    throw new Error('useAuthContext must be used within an AuthProvider');
  }
  return context;
};
