import React, { useState } from 'react';
import { faClock, faPlusCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Card, Row, Col } from 'react-bootstrap';
import IperxMeetingCreateForm from './IperxMeetingCreateForm';
import IperxMeetingPlanMeetingForm from './IperxMeetingPlanMeetingForm';
import IperxMeetingParticipateForm from './IperxMeetingParticipateForm';
import Calendar from './calendar/Calendar';
import IperxCallingPage from '../IperxCall/IperxCallingPage';
import { Link } from 'react-router-dom';

const IperxMeetingCall = () => {

    return (
        <>
            <section>
                <Row>
                    <Col md={3}>
                        <Link to="/apps/iperx/reunion" style={{ textDecoration: 'none', color: 'black' }}>
                            <Card className={`mb-3`} >
                                <FontAwesomeIcon icon={faPlusCircle} className="m-3" size="2x" />
                                <p className="m-0 text-center">Créer</p>
                            </Card>
                        </Link>
                        <Card className={`mb-3 `}>
                            <FontAwesomeIcon icon={faClock} className="m-3" size="2x" />
                            <p className="m-0 text-center">Planifier à une réunion</p>
                        </Card>
                        <Card className={`border border-primary mb-3 rounded-pill `} >
                            <p className="m-2 text-center text-uppercase fs-9">Participer à une réunion</p>
                        </Card>
                        <Card className={`mb-3 rounded-pill`}>
                            <p className="m-2 text-center text-uppercase fs-9">Afficher les réunions à venir</p>
                        </Card>
                    </Col>
                    <Col md={9}>
                        <IperxCallingPage />
                    </Col>
                </Row>
            </section>

        </>
    );
};

export default IperxMeetingCall;
