import { Col, Form, Button, Card } from 'react-bootstrap';
import { Link } from 'react-router-dom';


const IperxMeetingParticipateForm = () => {
    return (
        <>
            <Col className="m-4 mb-1">
                <h1 className="fs-6">Rejoindre une réunion</h1>
            </Col>
            <Col className="ms-4 mt-1 me-4">
                <p className="m-2 fs-9">Sélectionner la réunion à rejoindre</p>
                <Form.Select>
                    <option className="fw-bold">Sélectionner la réunion à rejoindre</option>
                    <option value="1" className="fw-bold">https://reunion-titre.iperx</option>
                    <option value="2" className="fw-bold">https://reunion-titre.iperx</option>
                    <option value="3" className="fw-bold">https://reunion-titre.iperx</option>
                </Form.Select>
                <Link to="/apps/iperx/reunion/appel" className='btn btn-primary m-2'>
                    Rejoindre la réunion
                </Link>
            </Col>
            <Col className="ms-4 mt-1 me-4">
                <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                    <Form.Label className="fs-12">URL autre réunion</Form.Label>
                    <Form.Control type="text" placeholder="" />
                </Form.Group>
                <Button variant="primary" className='m-2'>
                    Rejoindre la réunion
                </Button>
            </Col>

        </>
    );
};

export default IperxMeetingParticipateForm;
