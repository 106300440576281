import IperxNavigationCallingPage from "components/list-items/IperxNavigationCallingPage";
import profileImage from "assets/img/team/59.webp";
import { Row, Col, Card, Button } from "react-bootstrap";
import IperxProfileCalling from "components/cards/ipbx/IperxProfileCalling";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link } from "react-router-dom";
import { faMessage, faMicrophone, faMicrophoneAltSlash, faPhoneFlip, faShareSquare, faUsers, faVideo } from "@fortawesome/free-solid-svg-icons";
import IperxCallingNav from "./IperxCallingNav";
import { conversations } from "data/chat";
import ChatConversationDefault from "pages/apps/chat/iperx/ChatConversationDefault";
import ChatProvider from "providers/ChatProvider";
import { useState } from "react";
import IperxCallingConferenceButton from './IperxCallingConferenceModal';


const IperxCallingConference: React.FC<{ show: boolean }> = ({ show }) => {
  const [isActive, setIsActive] = useState(false);
  const [icon, setIcon] = useState(faMicrophone);
  const [showModal, setShowModal] = useState(false);
  const [showChat, setShowChat] = useState(false);

  const handleMuteMicrophone = () => {
    setIsActive(prevState => !prevState);
    setIcon(prevState => (prevState === faMicrophone ? faMicrophoneAltSlash : faMicrophone));
  };

  const toggleChatVisibility = () => {
    setShowChat(prevState => !prevState);
  };
  return (
    <>
      <Card>
        <div>
          <div className="d-flex justify-content-end m-2 me-4 pb-2 gap-3 gap-sm-6 gap-xxl-2 align-items-center border-bottom">
            <Link to="#" onClick={() => setShowModal(true)}>
              <FontAwesomeIcon icon={faUsers} className="me-5" />
            </Link>
            <Link to="#" onClick={toggleChatVisibility}>
              <FontAwesomeIcon icon={faMessage} className="me-5" />
            </Link>
            <span className="me-5">|</span>
            <Link to="/apps/iperx/utilisateurs/appel/conference/video">
              <FontAwesomeIcon icon={faVideo} className="me-5" />
            </Link>
            <Link to="#" onClick={handleMuteMicrophone}>
              <FontAwesomeIcon icon={icon} className="me-5" />
            </Link>
            <Link to="#">
              <FontAwesomeIcon icon={faShareSquare} className="me-5" />
            </Link>
            <Link to="/apps/iperx/utilisateurs/appel">
              <Button variant="danger">Arrêter</Button>
            </Link>
          </div>
          <IperxCallingConferenceButton show={showModal} handleClose={() => setShowModal(false)} />
          <div className="mt-0 mb-2 text-center d-flex justify-content-center">
            <Row className="g-2 flex-column align-items-center" style={{ width: showChat ? '77%' : '100%' }}>
              <Col className="d-none d-lg-block">
                <Row className="justify-content-center">
                  <img src="/static/media/59.aef05151533fb509baba.webp" alt="Profile" style={{ width: '12%', height: '12%', margin: '15px', borderRadius: '129px' }} />
                  <img src="/static/media/59.aef05151533fb509baba.webp" alt="Profile" style={{ width: '12%', height: '12%', margin: '15px', borderRadius: '129px' }} />
                  <img src="/static/media/59.aef05151533fb509baba.webp" alt="Profile" style={{ width: '12%', height: '12%', margin: '15px', borderRadius: '129px' }} />
                  <img src="/static/media/59.aef05151533fb509baba.webp" alt="Profile" style={{ width: '12%', height: '12%', margin: '15px', borderRadius: '129px' }} />
                  <img src="/static/media/59.aef05151533fb509baba.webp" alt="Profile" style={{ width: '12%', height: '12%', margin: '15px', borderRadius: '129px' }} />
                  <img src="/static/media/59.aef05151533fb509baba.webp" alt="Profile" style={{ width: '12%', height: '12%', margin: '15px', borderRadius: '129px' }} />
                </Row>

                <IperxProfileCalling
                  avatar={profileImage}
                />
              </Col>
              <Col className="d-none d-lg-block mt-0 mb-0">
                <div>
                  <Link to="/apps/iperx/utilisateurs/appel" className="text-error">
                    <FontAwesomeIcon icon={faPhoneFlip} className="text-danger fa-2x mb-2" style={{ transform: 'rotate(225deg)' }} />
                  </Link>
                </div>
              </Col>
              <Col xs={12} lg={5} className="mb-2 mt-0 m-3">
                <IperxNavigationCallingPage />
              </Col>
            </Row>
            {showChat && (
              <Row className="align-items-end" style={{ width: '33%' }}>
                {/* <ChatProvider conversations={conversations}> */}
                <Col xs={2} sm={5} md={10} xl={12} className="border-start chat">
                  <ChatConversationDefault />
                </Col>
                {/* </ChatProvider> */}
              </Row>
            )}
          </div>
        </div>
      </Card>
    </>
  );
};

export default IperxCallingConference;
