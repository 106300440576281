import { ColumnDef } from "@tanstack/react-table";
import AdvanceTable from "components/base/AdvanceTable";
import { Link } from "react-router-dom";
import AdvanceTableFooter from "components/base/AdvanceTableFooter";
import Avatar from "components/base/Avatar";
import { usersIperxMessagingDataType } from "../../../data/iper-x/ipbx/usersIperx";
import RevealDropdown, { RevealDropdownTrigger } from "components/base/RevealDropdown";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCirclePlay, faSquarePhone, faVideoCamera, faMessage } from "@fortawesome/free-solid-svg-icons";

export const usersIperxMessagingColumns: ColumnDef<usersIperxMessagingDataType>[] = [
  {
    accessorKey: 'user.name',
    header: 'Nom',
    cell: ({ row: { original } }) => {
      const {
        user: { name, avatar, status }
      } = original;
      console.log(avatar)
      if (avatar === '/static/media/avatar-placeholder.8e19382d3ac76b94f383.webp') {
        return (
          <Link
            to={`/apps/iperx/utilisateurs/details/${name}`}
            className="d-flex align-items-center text-1000"
          >
            <Avatar src={avatar} size="m" className="me-3" />
            <h6 className="mb-0 text-1000 fw-bold">{name}</h6>
          </Link>
        );
      } else {
        return (
          <Link
            to={`/apps/iperx/utilisateurs/details/${name}`}
            className="d-flex align-items-center text-1000"
          >
            <Avatar src={avatar} size="m" className="me-3" status={status} />
            <h6 className="mb-0 text-1000 fw-bold">{name}</h6>
          </Link>
        );
      }
    },
    meta: {
      headerProps: {
        style: { width: '20%', minWidth: '100px' },
        className: 'pe-3 ps-0'
      },
      cellProps: { className: 'white-space-nowrap py-2 ps-0' }
    }
  },
  {
    accessorKey: 'numero',
    header: 'Numéro',
    cell: ({ row: { original } }) => original.number,
    meta: {
      headerProps: {
        style: { width: '20%', minWidth: '60px' },
        className: 'pe-6'
      },
      cellProps: {
        className: 'white-space-nowrap text-start fw-bold text-700 py-2 pe-6 ms-3'
      }
    }
  },
  {
    accessorKey: "date",
    header: "Date d'appel",
    meta: {
      headerProps: { style: { width: "20%" } },
      cellProps: { className: "text-900 fs-9 ms-3" }
    }
  },
  {
    accessorKey: "lecture",
    header: "Lecture",
    cell: ({ row: { original } }) => (
      <div className="d-flex align-items-center ms-3">
        <FontAwesomeIcon
          icon={faCirclePlay}
          style={{ fontSize: "25px", color: "purple" }}
          transform="shrink-2 up-1"
        />
      </div>
    ),
    meta: {
      headerProps: { style: { width: "20%" } },
      cellProps: { className: "text-900 fs-9" }
    }
  },
  {
    accessorKey: 'messageTime',
    header: 'Durée du message',
    cell: ({ row: { original } }) => original.sync_time,
    meta: {
      headerProps: {
        style: { width: '20%', minWidth: '60px' },
        className: 'pe-6'
      },
      cellProps: {
        className: 'white-space-nowrap text-start fw-bold text-700 py-2 pe-6 ms-3'
      }
    }
  },
  {
    accessorKey: "option",
    header: "",
    cell: ({ row: { original } }) => {
      const {
        user: { name }
      } = original;
      return (
        <div className="d-flex align-items-center justify-content-center flex-1">
          <Link to={`/apps/iperx/utilisateurs/appel/${name}`} className="me-3">
            <FontAwesomeIcon
              icon={faSquarePhone}
              style={{ fontSize: "25px" }}
              transform="shrink-2 up-1"
            />
          </Link>
          <Link to={`/apps/iperx/utilisateurs/appel/video/${name}`} className="me-3">
            <FontAwesomeIcon
              icon={faVideoCamera}
              style={{ fontSize: "28px" }}
              transform="shrink-2 up-1"
            />
          </Link>
          <Link to={`/apps/iperx/utilisateurs/message/${name}`} className="me-3">
            <FontAwesomeIcon
              icon={faMessage}
              style={{ fontSize: "22px" }}
              transform="shrink-2 up-1"
            />
          </Link>
          <RevealDropdownTrigger>
            <RevealDropdown>
              <Link to={`/apps/iperx/utilisateurs/details/${name}`} className="d-flex align-items-center text-1000 m-1">
                Modifier
              </Link>
            </RevealDropdown>
          </RevealDropdownTrigger>
        </div>
      );
    },
    meta: {
      headerProps: { style: { width: '10%' }, className: 'text-end' },
    }
  },
];

const IperxUsersMessagingTable = () => {
  return (
    <div>
      <AdvanceTable tableProps={{ className: "phoenix-table fs-9" }} />
      <AdvanceTableFooter pagination />
    </div>
  );
};

export default IperxUsersMessagingTable;
