import React, { useState } from 'react';
import { faArrowLeft, faPhoneAlt } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import { Card, Row, Col, Button, Form } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { IperxDropdowCallingTaplabels } from 'data/social/IperxDropdowData';
import IperxCallingTapTools from './IperxCallingTapTools';

const IperxCallingTapPage = ({ className }: { className?: string }) => {

  const [callNumber, setCallNumber] = useState<string>('');

  return (
    <>+
      <IperxCallingTapTools callNumber={callNumber} setCallNumber={setCallNumber} />
      <Col xs={12} className="text-center py-2" style={{ position: 'relative', top: '-100px' }}>
        <Link to={`/apps/iperx/utilisateurs/appel/${callNumber}`} className="text-success" aria-disabled={!callNumber}>
          <FontAwesomeIcon icon={faPhoneAlt} className="text-success fa-2x" />
        </Link>
      </Col>
    </>
  );
};

export default IperxCallingTapPage;
