import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { faHourglassHalf, faStopCircle } from "@fortawesome/free-regular-svg-icons";
import { Icon, UilCube, UilDocumentLayoutRight, UilFilesLandscapesAlt, UilPuzzlePiece } from "@iconscout/react-unicons";

// import {faStopCircle} from "@fortawesome/free-solid-svg-icons";

export interface Route {
  name: string;
  icon?: IconProp | string | string[];
  iconSet?: 'font-awesome' | 'feather' | 'unicons';
  pages?: Route[];
  path?: string;
  pathName?: string;
  flat?: boolean;
  topNavIcon?: string;
  dropdownInside?: boolean;
  active?: boolean;
  new?: boolean;
  hasNew?: boolean;
}

export interface RouteItems {
  label: string;
  horizontalNavLabel?: string;
  icon: Icon;
  labelDisabled?: boolean;
  pages: Route[];
  megaMenu?: boolean;
  active?: boolean;
}

export let routes: RouteItems[];
routes = [



  {
    label: "WebRTC",
    icon: UilCube,
    pages: [

      {
        name: 'Utilisateurs',
        path: "/apps/iperx/utilisateurs",
        pathName: 'portail-iperx-utilisateurs',
        icon: 'user',
        active: true
      },
      {
        name: "Chat",
        path: "/apps/iperx/chat",
        pathName: "portail-iperx-chat",
        icon: "message-square",
        active: true
      }, {
        name: "Réunion",
        path: "/apps/iperx/reunion",
        pathName: "portail-iperx-reunion",
        icon: "video",
        active: true
      }, {
        name: "Historique appels",
        path: "/apps/iperx/historique-appel",
        pathName: "portail-iperx-historique-appel",
        icon: "phone",
        active: true
      }, {
        name: "Contacts",
        path: "/apps/iperx/contact",
        pathName: "portail-iperx-contact",
        icon: "user",
        active: true
      }, {
        name: "Messagerie",
        path: "/apps/iperx/messagerie",
        pathName: 'portail-iperx-messagerie',
        icon: "mail",
        active: true
      }, {
        name: "FAQ",
        path: "/apps/iperx/faq",
        pathName: "portail-iperx-faq",
        icon: "help-circle",
        active: true
      }, {
        name: "Paramètres",
        path: "/apps/iperx/parametres",
        pathName: 'portail-iperx-parametre',
        icon: "settings",
        active: true
      }
    ]
  },


];
