import MainLayout from "layouts/MainLayout";
import { createBrowserRouter, RouteObject } from "react-router-dom";

import MainLayoutProvider from "providers/MainLayoutProvider";
import Error404 from "pages/error/Error404";
import Error403 from "pages/error/Error403";
import Error500 from "pages/error/Error500";
import App from "App";
import React, { lazy } from "react";
import FaqIpbx from "./pages/apps/webrtc/FaqIpbx";
import SignInHard from "./pages/auth/SignInHard";
import ChatConversation from "pages/apps/chat/ChatConversation";
import ChatHomepage from "pages/apps/chat/ChatHomepage";
import IperxMessagerie from "pages/apps/webrtc/IperxMessagerie";
import IperxParamChat from "pages/apps/webrtc/IperxParam/IperxParamChat";
import IperxParamAnnonce from "pages/apps/webrtc/IperxParam/IperxParamAnnonce";
import IperxParamIntegration from "pages/apps/webrtc/IperxParam/IperxParamIntegration";
import IperxParamQRcode from "pages/apps/webrtc/IperxParam/IperxParamQRcode";
import IperxParamRaccourci from "pages/apps/webrtc/IperxParam/IperxParamRaccourci";
import IperxParamRepository from "pages/apps/webrtc/IperxParam/IperxParamRepository";
import IperxParamTransfertAppel from "pages/apps/webrtc/IperxParam/Transfert d'appel/IperxParamTransfertAppel";
import IperxParamVisualiser from "pages/apps/webrtc/IperxParam/IperxParamVisualiser";
import IperxHistoryUserCalls from "pages/apps/webrtc/IperxHistoryUserCalls";
import IperxCallingPage from "pages/apps/webrtc/IperxCall/IperxCallingPage";
import IperxMessagePage from "pages/apps/webrtc/IperxChat";
import IperxChat from "pages/apps/webrtc/IperxChat";
import IperxMeetingPage from "pages/apps/webrtc/IperxMeetingFiles/IperxMeetingPage";
import IperxUsersProfile from "pages/apps/Ipbx/IperxUsersProfile";
import IperxContactPage from "pages/apps/webrtc/IperxContactPage";
import IperxCallingWaitingPage from "pages/apps/webrtc/IperxCall/IperxCallingWaitingPage";
import IperxCallingTapPage from "pages/apps/webrtc/IperxCall/IperxCallingTapPage";
import IperxParamAudioVideo from "pages/apps/webrtc/IperxParam/IperxParamAudioVideo";
import IperxParam from "pages/apps/webrtc/IperxParam/IperxParam";
import IperxCalendarCreateAnEvent from "pages/apps/webrtc/IperxMeetingFiles/calendar/events/IperxCalendarCreateAnEvent";
import IperxCalendarEventDetail from "pages/apps/webrtc/IperxMeetingFiles/calendar/events/IperxCalendarEventDetail";
import IperxCallingConference from "pages/apps/webrtc/IperxCall/IperxCallingConference";
import IperxCallingConferenceVideo from "pages/apps/webrtc/IperxCall/IperxCallingConferenceVideo";
import IperxMeetingCall from "pages/apps/webrtc/IperxMeetingFiles/IperxMeetingCall";
import IperxCallingPageVideo from "pages/apps/webrtc/IperxCall/IperxCallingPageVideo";
import IperxChatModal from "pages/apps/webrtc/IperxParam/IperxChatModal";
import IperxCallingWaitingPageVideo from "pages/apps/webrtc/IperxCall/IperxCallingWaitingPageVideo";
import IperxUsers from "pages/apps/webrtc/IperxUsers";
import IperxContactPageForm from "pages/apps/webrtc/IperxContactPageForm";

const FontAwesomeExample = lazy(
  () => import('pages/modules/components/FontAwesomeExample')
);

const FeatherIconsExample = lazy(
  () => import('pages/modules/components/FeatherIconsExample')
);

const UniconsExample = lazy(
  () => import('pages/modules/components/UniconsExample')
);

const routes: RouteObject[] = [
  {
    element: <App />,
    children: [
      {
        path: '/',
        element: (
          <MainLayoutProvider>
            <MainLayout />
          </MainLayoutProvider>
        ),
        children: [
          {
            path: '/apps',
            children: [
              {
                path: 'iperx',
                children: [
                  {
                    path: 'utilisateurs',
                    element: <IperxUsers />
                  },

                  {
                    path: 'utilisateurs/details/:name',
                    element: <IperxUsersProfile />
                  },
                  {
                    path: 'chat',
                    element: <IperxChat />,
                    children: [
                      {
                        index: true,
                        element: <ChatHomepage />
                      },
                      {
                        path: ':userId/conversation',
                        element: <ChatConversation />
                      },
                      {
                        path: ':userId/appel/conversation',
                        element: <IperxChatModal />
                      }
                      //A garder , hésitation sur l'utilisation du chat unique , id de préférence//
                      // {
                      //   path: ':userId/conversation',
                      //   element: <IperxUniqueChat />
                      // }
                    ]
                  },
                  {
                    path: 'reunion',
                    element: <IperxMeetingPage />
                  },
                  {
                    path: 'reunion/appel',
                    element: <IperxMeetingCall />
                  },
                  {
                    path: 'reunion-events',
                    children: [
                      {
                        path: 'create-an-event',
                        element: <IperxCalendarCreateAnEvent />
                      },
                      {
                        path: 'event-detail',
                        element: <IperxCalendarEventDetail />
                      }
                    ]
                  },
                  {
                    path: 'historique-appel',
                    element: <IperxHistoryUserCalls />
                  },
                  {
                    path: 'utilisateurs/appel',
                    element: <IperxCallingTapPage />
                  },
                  {
                    path: 'utilisateurs/appel-waiting/:name/:session',
                    element: <IperxCallingWaitingPage show={false} />
                  },
                  {
                    path: 'utilisateurs/appel-waiting/:name/video/:session',
                    element: <IperxCallingWaitingPageVideo show={false} />
                  },
                  {
                    path: 'utilisateurs/appel/:name/:session',
                    element: <IperxCallingPage show={false} />
                  },

                  {
                    path: 'utilisateurs/appel/:name/video/:session',
                    element: <IperxCallingPageVideo show={false} />
                  },
                  {
                    path: 'utilisateurs/appel/conference',
                    element: <IperxCallingConference show={false} />
                  },
                  {
                    path: 'utilisateurs/appel/conference/video',
                    element: <IperxCallingConferenceVideo show={false} />
                  },
                  {
                    path: 'utilisateurs/message/:name',
                    element: <IperxMessagePage />
                  },
                  {
                    path: 'utilisateurs/details/:name',
                    element: <IperxUsersProfile />
                  },
                  {
                    path: 'contact',
                    element: <IperxContactPage />
                  },
                  {
                    path: 'contact/formulaire',
                    element: <IperxContactPageForm />
                  },
                  {
                    path: 'messagerie',
                    element: <IperxMessagerie />
                  },
                  {
                    path: 'faq',
                    element: <FaqIpbx />
                  },
                  {
                    path: 'parametres',
                    element: <IperxParam />,
                    children: [
                      {
                        path: 'annonce',
                        element: <IperxParamAnnonce />
                      },
                      {
                        path: 'audio-video',
                        element: <IperxParamAudioVideo />
                      },
                      {
                        path: 'chat',
                        element: <IperxParamChat />
                      },
                      {
                        path: 'integration',
                        element: <IperxParamIntegration />
                      },
                      {
                        path: 'qrcode',
                        element: <IperxParamQRcode />
                      },
                      {
                        path: 'raccourci',
                        element: <IperxParamRaccourci />
                      },
                      {
                        path: 'repertoire',
                        element: <IperxParamRepository />
                      },
                      {
                        path: 'transfert-appel',
                        element: <IperxParamTransfertAppel />
                      },
                      {
                        path: 'visualiser',
                        element: <IperxParamVisualiser />
                      }
                    ]
                  }
                ]
              }
            ]
          }
        ]
      },

      {
        path: '/auth',
        children: [
          {
            path: 'login',
            element: <SignInHard />
          }
        ]
      },

      {
        path: '/pages/errors/',
        children: [
          {
            path: '404',
            element: <Error404 />
          },
          {
            path: '403',
            element: <Error403 />
          },
          {
            path: '500',
            element: <Error500 />
          }
        ]
      },

      {
        path: '*',
        element: <Error404 />
      }
    ]
  }
];

export const router = createBrowserRouter(routes);

export default routes;
