import { useEffect, useRef, useState } from 'react';
import { Card } from 'react-bootstrap';
import { useChatContext } from 'providers/ChatProvider';
import PhoenixOffcanvas, {
  PhoenixOffcanvasContainer
} from 'components/base/PhoenixOffcanvas';
import ConversationDetails from '../conversation-details';
import ChatSidebar from '../ChatSidebar';
import { useBreakpoints } from 'providers/BreakpointsProvider';

import ConversationStarter from './ConversationStarter';
import ChatContentFooter from './ChatContentFooter';
import Message from '../message';
import Scrollbar from 'components/base/Scrollbar';
import { SET_CHAT_STATE } from 'reducers/ChatReducer';
import ChatContentHeader from "./ChatContentHeader";
import { sipConfig } from 'sip/config';


const ChatContent = () => {
  const {
    currentConversation,
    chatDispatch,
    showConversationDetails,
    showUserListOffcanvas,
    setShowConversationDetails
  } = useChatContext();

  const [message, setMessage] = useState<string>('');
  const [receivedMessages, setReceivedMessages] = useState<{ from: string; body: string }[]>([]);

  const messageEndRef = useRef<null | HTMLSpanElement>(null);

  const { breakpoints } = useBreakpoints();
  // const sipClient = new SipClient(sipConfig);

  useEffect(() => {
    const connectClient = async () => {
      // await sipClient.userAgent.start();
      // sipClient.onMessageReceived((receivedMessage) => {
      //   setReceivedMessages((prevMessages) => [...prevMessages, receivedMessage]);
      // });
    };

    // connectClient();

    // return () => {
    //   sipClient.userAgent.stop();
    // };
  }, []);

  console.log({ message });

  const sendMessage = () => {
    // sipClient.sendMessage('sip:8013@ipbx1.ipercom.io', message); // replace with recipient's SIP URI
  };

  if (currentConversation) {
    return (
      <Card as={PhoenixOffcanvasContainer} className="h-100 w-100 border-0">
        <ChatContentHeader />
        <Card.Body className="p-3 p-sm-4 scrollbar d-flex flex-column gap-2">
          {currentConversation.messages.length === 0 && <ConversationStarter />}
          {currentConversation.messages.map(message => (
            <Message
              message={message}
              user={currentConversation.user}
              key={message.id}
            />
          ))}
          <span ref={messageEndRef} />
        </Card.Body>

        <ChatContentFooter sendMessage={sendMessage} setMessage={setMessage} message={message} />

        <PhoenixOffcanvas
          open={showConversationDetails}
          placement="top"
          noBackdrop
          className="bg-white w-100 z-index-0 rounded-3"
        >
          <Scrollbar>
            <ConversationDetails
              conversation={currentConversation}
              handleClose={() => setShowConversationDetails(false)}
            />
          </Scrollbar>
        </PhoenixOffcanvas>

        {breakpoints.down('sm') && (
          <PhoenixOffcanvas
            open={showUserListOffcanvas}
            placement="start"
            noBackdrop
            className="w-100 z-index-0"
          >
            <ChatSidebar className="border-0 h-100" />
          </PhoenixOffcanvas>
        )}
      </Card>
    );
  } else {
    return <></>;
  }
};

export default ChatContent;
