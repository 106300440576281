import React, { useCallback, useEffect, useState } from 'react';
import { Outlet, useLocation, Navigate, useNavigate } from 'react-router-dom';
import { AuthProvider, useAuthContext } from './providers/AuthProvider';
import SettingsToggle from './components/settings-panel/SettingsToggle';
import SettingsPanel from './components/settings-panel/SettingsPanel';
import { useSettingsPanelContext } from './providers/SettingsPanelProvider';
import useToggleStyle from './hooks/useToggleStyle';
import axios from 'axios';
import { Spinner } from 'react-bootstrap';
import { SIPProvider } from 'sip/SIPProvider';
import { Message, UserAgent } from 'sip.js';
import { useSIPProvider } from 'sip/SIPProviderContext';
import { RECEIVE_MESSAGE } from 'reducers/ChatReducer';
import ChatProvider, { useChatContext } from 'providers/ChatProvider';
import { conversations } from 'data/chat';
// import { SIPProvider } from "react-sipjs";

const App = () => {
  const [clientIP, setClientIP] = useState('');
  const [ipCheckLoading, setIpCheckLoading] = useState(true); // New loading state for IP check
  const exemptedIP = '217.128.32.10';
  const { isStylesheetLoaded } = useToggleStyle();
  const { pathname } = useLocation();
  const {
    settingsPanelConfig: { showSettingPanelButton },
    setSettingsPanelConfig
  } = useSettingsPanelContext();

  const navigate = useNavigate()

  const {
    isAuthenticated,
    loading,
    config: { theme, isRTL }
  } = useAuthContext();

  const {
    connectAndRegister,
    sessionManager,
    sessions,
    registerStatus,
    connectStatus,
  } = useSIPProvider();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  useEffect(() => {
    setSettingsPanelConfig({
      openSettingPanel: false
    });
  }, [isRTL]);

  useEffect(() => {
    connectAndRegister({})
    navigate('/apps/iperx/utilisateurs/');
  }, [])




  useEffect(() => {
    // Fetch client IP address
    axios.get('https://api.ipify.org?format=json')
      .then(response => {
        console.log("Client IP:", response.data.ip); // Log for debugging
        setClientIP(response.data.ip);
        setIpCheckLoading(false); // Set loading to false after getting IP
      })
      .catch(error => {
        console.error('Error fetching IP address:', error);
        setIpCheckLoading(false); // Ensure loading state is updated even if there's an error
      });
  }, []);

  if (loading || ipCheckLoading) {
    // Wait for both auth and IP check to complete
    return (
      <Spinner animation="border" role="status">
        <span className="visually-hidden">Loading...</span>
      </Spinner>
    );
  }
  // && clientIP !== exemptedIP

  // const handleIncomingCall = (e: { session: any }) => {
  //   const session = e.session;

  //   // Handle incoming call events like 'accepted', 'failed', and 'ended'
  //   session.on('accepted', () => {
  //     console.log('Call answered');
  //     // Set up audio/video streams (if enabled)
  //     handleMediaStreams(session);
  //   });

  //   session.on('failed', () => {
  //     console.log('Call failed');
  //   });

  //   session.on('ended', () => {
  //     console.log('Call ended');
  //     // Clean up media streams and session state
  //     setSipSession(null);
  //   });
  // };

  if (!isAuthenticated && pathname !== '/auth/login') {
    console.log('Redirecting to login, IP not matched'); // Log for debugging
    return <Navigate to="/auth/login" />;
  }

  return (
    <>
      {!isStylesheetLoaded ? (
        <div
          style={{
            position: 'fixed',
            top: 0,
            right: 0,
            bottom: 0,
            left: 0,
            backgroundColor: theme === 'dark' ? '#000' : '#fff'
          }}
        />
      ) : (
        <>
          <Outlet />
          {showSettingPanelButton && (
            <>
              <SettingsToggle />
              <SettingsPanel />
            </>
          )}
        </>
      )}
    </>
  );
};

const AppWithAuthProvider = () => {
  const { currentConversation, chatDispatch } = useChatContext();

  const onMessageReceived = useCallback((message: Message) => {
    console.log("Received message in App:", message);
    try {
      if (message.request.from.uri.user && message.request.body) {
        chatDispatch({
          type: RECEIVE_MESSAGE,
          payload: {
            conversationId: Number(message.request.from.uri.user),
            message: message.request.body,
          },
        });
      }
    } catch (error) {
      console.error("Failed to dispatch message:", error);
    }
  }, [chatDispatch]);

  return (
    <AuthProvider>
      <ChatProvider conversations={conversations}>
        <SIPProvider
          options={{
            domain: process.env.REACT_APP_SIP_DOMAIN ?? "",
            webSocketServer: process.env.REACT_APP_WS_SERVERS ?? "",
            username: process.env.REACT_APP_SIP_USERNAME ?? "",
            password: process.env.REACT_APP_SIP_PASSWORD ?? ""
          }}
          onMessageReceived={onMessageReceived}
        >
          <App />
        </SIPProvider>
      </ChatProvider>
    </AuthProvider>
  );
};

export default AppWithAuthProvider;
