import React, { useState } from 'react';
import { Button, Col, Row, Modal, Form } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFileExport, faPlus, faPhone } from '@fortawesome/free-solid-svg-icons';
import SearchBox from 'components/common/SearchBox';
import useAdvanceTable from 'hooks/useAdvanceTable';
import AdvanceTableProvider from 'providers/AdvanceTableProvider';
import LeadsFilterModal from 'components/modals/LeadsFilterModal';
import IperxUsersTable, { usersIperxTableColumns } from 'components/tables/ipbx/IperxUsersTable';
import { usersIperxTableData } from 'data/iper-x/ipbx/usersIperx';
import { Link } from 'react-router-dom';

const IperxUsers = () => {
  const table = useAdvanceTable({
    data: usersIperxTableData,
    columns: usersIperxTableColumns,
    pageSize: 10,
    pagination: true,
    sortable: true,
    selection: true,
  });

  const [openFilterModal, setOpenFilterModal] = useState(false);
  const [showAddUserModal, setShowAddUserModal] = useState(false);

  const handleSearchInputChange = (e) => {
    table.setGlobalFilter(e.target.value || undefined);
  };

  const handleAddUserClick = () => {
    setShowAddUserModal(true);
  };

  const handleCloseAddUserModal = () => {
    setShowAddUserModal(false);
  };

  const handleSubmit = (e) => {
    // Gérer la soumission du formulaire ici
    e.preventDefault();
  };

  const handleChange = (e) => {
    // Gérer les changements dans le formulaire ici
    // Assurez-vous d'avoir un état pour stocker les valeurs du formulaire
  };

  return (
    <div>
      <AdvanceTableProvider {...table}>
        <div className="d-flex flex-wrap mb-4 gap-3 gap-sm-6 gap-xxl-2 align-items-center">
          <h2 className="mb-0">
            <span className="me-3">Utilisateurs</span>
          </h2>
        </div>
        <div className="mb-4 m-2">
          <Row className="g-3">
            <Col xs="auto">
              <SearchBox
                className="w-300"
                placeholder="Rechercher"
                onChange={handleSearchInputChange}
              />
            </Col>
            <Col xs="auto" className="scrollbar overflow-hidden-y flex-grow-1"></Col>
            <Col xs="auto">
              <Button variant="link" className="text-900 me-4 px-0">
                <FontAwesomeIcon icon={faFileExport} className="fs-9 me-2" />
                Exporter
              </Button>
              <Button variant="primary" onClick={handleAddUserClick}>
                <FontAwesomeIcon icon={faPlus} className="me-2" />
                Ajouter un utilisateur
              </Button>
              <Link to={`/apps/iperx/utilisateurs/appel`} className="ms-4">
                <Button variant="primary">
                  <FontAwesomeIcon icon={faPhone} className="me-2" />
                  Appeler un numéro
                </Button>
              </Link>
              <LeadsFilterModal
                show={openFilterModal}
                handleClose={() => setOpenFilterModal(false)}
              />


            </Col>
          </Row>
        </div>
        <div className="mx-n4 px-4 mx-lg-n6 px-lg-6 bg-white border-top border-bottom border-200 position-relative top-1">
          <IperxUsersTable />
        </div>
      </AdvanceTableProvider>

      <Modal show={showAddUserModal} onHide={handleCloseAddUserModal}>
        <Modal.Header closeButton>
          <Modal.Title>Ajouter un utilisateur</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form onSubmit={handleSubmit}>
            <Form.Group controlId="NOM D’UTILISATEUR">
              <Form.Label>NOM D’UTILISATEUR</Form.Label>
              <Form.Control type="text" name="NOM D’UTILISATEUR" onChange={handleChange} required />
            </Form.Group>

            <Form.Group controlId="NUMÉRO DE POSTE">
              <Form.Label>NUMÉRO DE POSTE</Form.Label>
              <Form.Control type="text" name="NUMÉRO DE POSTE" onChange={handleChange} required />
            </Form.Group>

            <Form.Group controlId="EMAIL">
              <Form.Label>EMAIL</Form.Label>
              <Form.Control type="email" name="EMAIL" onChange={handleChange} required />
            </Form.Group>

            <Form.Group controlId="MOBILE">
              <Form.Label>MOBILE</Form.Label>
              <Form.Control type="text" name="MOBILE" onChange={handleChange} required />
            </Form.Group>

            <Form.Group controlId="ID D’APPELANT">
              <Form.Label>ID D’APPELANT</Form.Label>
              <Form.Control type="text" name="ID D’APPELANT" onChange={handleChange} required />
            </Form.Group>

            <Form.Group controlId="FAVORIS" className='mt-2'>
              <Form.Check
                type="checkbox"
                name="FAVORIS"
                label="Ajouter en favoris"
                onChange={handleChange}
              />
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseAddUserModal}>
            Annuler
          </Button>
          <Button variant="primary" onClick={handleSubmit}>
            Ajouter
          </Button>
        </Modal.Footer>
      </Modal>

    </div>
  );
};

export default IperxUsers;
