import {
  faMicrophone,
  faMicrophoneAltSlash,
  faUsers,
  faMessage,
  faVideo,
  faShareSquare,
  faPhoneAlt,
  faPhoneFlip,
  faVideoSlash
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import IperxProfileWaitingCalling from 'components/cards/ipbx/IperxProfileWaitingCalling';
import IperxNavigationCallingPage from 'components/list-items/IperxNavigationCallingPage';
import { conversations } from 'data/chat';
import ChatConversationDefault from 'pages/apps/chat/iperx/ChatConversationDefault';
import ChatProvider from 'providers/ChatProvider';
import { useEffect, useRef, useState } from 'react';
import { Card, Button, Row, Col } from 'react-bootstrap';
import { Link, useParams } from 'react-router-dom';
import IperxCallingConferenceButton from './IperxCallingConferenceModal';
import profileImage from 'assets/img/team/59.webp';
import { sipConfig } from 'sip/config';
import { useSIPProvider } from 'sip/SIPProviderContext';
import { Session, SessionState } from 'sip.js';

const IperxCallingWaitingPage: React.FC<{ show: boolean }> = ({ show }) => {
  // const { client } = useSipClient();

  const [isActive, setIsActive] = useState(false);
  const [icon, setIcon] = useState(faMicrophone);
  const [showModal, setShowModal] = useState(false);
  const [showChat, setShowChat] = useState(false);
  // const localStreamRef = useRef(new MediaStream());
  // const remoteAudioRef = useRef(null);

  const params = useParams();
  const { name, session } = params;

  const {
    connectAndRegister,
    sessionManager,
    sessions,
    registerStatus,
    connectStatus,
  } = useSIPProvider();

  const handleMuteMicrophone = () => {
    setIsActive(prevState => !prevState);
    setIcon(prevState =>
      prevState === faMicrophone ? faMicrophoneAltSlash : faMicrophone
    );
  };

  const toggleChatVisibility = () => {
    setShowChat(prevState => !prevState);
  };
  // const startCall = async () => {
  //   try {
  //     const client = new SipClient(sipConfig);
  //     if (client) {
  //       client.call(`sip:8012@ipbx1.ipercom.io`, {
  //         audio: true,
  //         video: false
  //       });

  //       const stream = await navigator.mediaDevices.getUserMedia({
  //         audio: true,
  //         video: false
  //       });
  //       localStreamRef.current = stream;
  //     }
  //   } catch (error) {
  //     console.error('Error accessing media devices.', error);
  //   }
  // };

  // useEffect(() => {
  //   startCall();
  // }, []);

  const getSessionById = (session: any, sessions: Record<string, Session>) => {
    return sessions[session] || null;
  }

  function getEstablishingSessions(sessions: Record<string, Session>) {
    const establishingSessions = [];
    for (const id in sessions) {
      if (sessions[id].state === SessionState.Establishing) {
        establishingSessions.push({ id, session: sessions[id] });
      }
    }
    return establishingSessions;
  }

  // Get the sessions with _state 'Establishing'


  const handleEndCall = async () => {
    // if(sessionManager)sessionManager.sessions.forEach(session => session.terminate());
    // sessionManager?.decline()
    const establishingSessions = getEstablishingSessions(sessions);
    console.log("decline call", establishingSessions)
    if (sessionManager) {
      // Iterate over each establishing session and hang up
      for (const { session } of establishingSessions) {
        try {
          console.log("decline call", session)
          await session.bye()
          console.log(`Session hung up successfully.`);
        } catch (error) {
          console.error(`Error hanging up session :`, error);
        }
      }
    }
  }
  console.log(sessions)


  return (
    <>
      <Card>
        <div className="d-flex justify-content-end m-2 me-4 pb-2 gap-3 gap-sm-6 gap-xxl-2 align-items-center border-bottom">
          <Link to="#" onClick={() => setShowModal(true)}>
            <FontAwesomeIcon icon={faUsers} className="me-5" />
          </Link>
          <Link to="#" onClick={toggleChatVisibility}>
            <FontAwesomeIcon icon={faMessage} className="me-5" />
          </Link>
          <span className="me-5">|</span>
          <Link to={`/apps/iperx/utilisateurs/appel-waiting/${name}/video/${session}`}>
            <FontAwesomeIcon icon={faVideo} className="me-5" />
          </Link>
          <Link to="#" onClick={handleMuteMicrophone}>
            <FontAwesomeIcon icon={icon} className="me-5" />
          </Link>
          <Link to="#">
            <FontAwesomeIcon icon={faShareSquare} className="me-5" />
          </Link>
          {/* <Link to="/apps/iperx/utilisateurs/" > */}
          <Button onClick={handleEndCall} variant="danger">Arrêter</Button>
          {/* </Link> */}
        </div>
        <IperxCallingConferenceButton
          show={showModal}
          handleClose={() => setShowModal(false)}
        />

        <div className="mt-0 mb-2 text-center d-flex">
          <Row
            className="g-2 flex-column align-items-center"
            style={{ width: showChat ? '77%' : '100%' }}
          >
            <Col className="d-none d-lg-block">
              <IperxProfileWaitingCalling avatar={profileImage} />
              {/* <audio ref={remoteAudioRef} autoPlay /> */}
            </Col>
            <Col className="d-none d-lg-block">
              <div>
                <Button
                  to={`/apps/iperx/utilisateurs/appel/${name}/${session}`}
                  className="text-success"
                >
                  <FontAwesomeIcon
                    icon={faPhoneAlt}
                    className="me-15 text-success fa-2x"
                  />
                </Button>
                <Button
                  // to="/apps/iperx/utilisateurs"
                  className="text-error"
                  onClick={handleEndCall}
                >
                  <FontAwesomeIcon
                    icon={faPhoneFlip}
                    className="text-danger fa-2x"
                    style={{ transform: 'rotate(225deg)' }}
                  />
                </Button>
              </div>
            </Col>
            <Col xs={12} lg={5} className="mb-2 mt-0 m-3">
              <IperxNavigationCallingPage />
            </Col>
          </Row>
          {showChat && (
            <Row className="align-items-end" style={{ width: '33%' }}>
              {/* <ChatProvider conversations={conversations}> */}
              <Col
                xs={2}
                sm={5}
                md={10}
                xl={12}
                className="border-start chat"
              >
                <ChatConversationDefault />
              </Col>
              {/* </ChatProvider> */}
            </Row>
          )}
        </div>
      </Card>
    </>
  );
};

export default IperxCallingWaitingPage;
