import { ReactNode, useCallback, useRef, useState } from "react";
import React from "react";
import { Session } from "sip.js/lib/api/session";
import { SessionManager } from "sip.js/lib/platform/web";
import { ErrorMessageLevel1, ErrorMessageLevel2 } from "./error";
import {
    RegisterStatus,
    SIPAccount,
    SIPProviderOptions,
    CONNECT_STATUS,
    SessionTimer,
} from "./type";
import { ProviderContext } from "./SIPProviderContext";
import { useNavigate } from "react-router-dom";
import { Invitation, Inviter, Message, SessionState } from "sip.js";
import { useChatContext } from "providers/ChatProvider";
import { RECEIVE_MESSAGE, SENT_MESSAGE } from "reducers/ChatReducer";
import { useChatWidgetContext } from "providers/ChatWidgetProvider";

interface MediaConstraints {
    audio: boolean;
    video: boolean;
}

export const SIPProvider = (props: {
    options: SIPProviderOptions;
    children: ReactNode | JSX.Element;
    onMessageReceived: (message: Message) => void;
}): JSX.Element => {
    const { options, children } = props;
    const refAudioRemote = useRef<HTMLAudioElement>(null);

    const [sessions, setSessions] = useState<Record<string, Session>>({});
    const [sessionTimer, setSessionTimer] = useState<SessionTimer>({});
    const [sessionManager, setSessionManager] = useState<SessionManager | null>(
        null
    );
    const [activeCalls, setActiveCalls] = useState<any>({});

    // const { conversation, receiveMessage } = useChatWidgetContext();
    const { currentConversation, chatDispatch } = useChatContext();
    const navigate = useNavigate()
    const [connectStatus, setStatus] = useState<CONNECT_STATUS>(
        CONNECT_STATUS.WAIT_REQUEST_CONNECT
    );
    const [registerStatus, setRegisterStatus] = useState<RegisterStatus>(
        RegisterStatus.UNREGISTERED
    );

    const updateSession = useCallback(
        (session: Session) => {
            setSessions((sessions) => ({
                ...sessions,
                [session.id]: session,
            }));
        },
        [setSessions]
    );

    const connectAndRegister = useCallback((sipAccount?: SIPAccount) => {
        const sessionManager = new SessionManager(options.webSocketServer, {
            aor: `sip:${options.username}@${options.domain}`,
            userAgentOptions: {
                authorizationUsername: options.username,
                authorizationPassword: options.password,
            },
            media: {
                constraints: {
                    audio: true,
                    video: true,
                },
                remote: {
                    audio: refAudioRemote.current as HTMLAudioElement,
                },
            },
            delegate: {
                onCallCreated: (session) => {
                    const caller = session?.remoteIdentity?.uri?.user ?? '';

                    if (caller && activeCalls[caller]) {
                        console.log(`Call from ${caller} is already active. Rejecting new call.`);
                        session.bye();
                    } else {
                        setActiveCalls((prevActiveCalls: any) => ({
                            ...prevActiveCalls,
                            [caller]: session.remoteIdentity.uri.user,
                        }));
                        session.stateChange.addListener((state) => {
                            console.info(
                                ErrorMessageLevel1.SIP_PROVIDER,
                                `Session ${session.id} changed to ${state}`
                            );
                            updateSession(session);
                        });
                        updateSession(session);
                        setSessionTimer((timer) => ({
                            ...timer,
                            [session.id]: {
                                createdAt: new Date(),
                            },
                        }));
                    }
                    switch (session.state) {
                        case SessionState.Initial:
                        case SessionState.Establishing:
                            const mediaConstraints: MediaConstraints = session?.sessionDescriptionHandlerOptions?.constraints as MediaConstraints;
                            console.log("mediaConstraints, mediaConstraints", session, mediaConstraints)
                            // session.cancel();
                            console.log("session.remoteIdentity.uri.user", session.remoteIdentity.uri.user)

                            if (mediaConstraints?.video)
                                navigate(`/apps/iperx/utilisateurs/appel/${session.remoteIdentity.uri.user}/video/${session.id}`);
                            else
                                navigate(`/apps/iperx/utilisateurs/appel/${session.remoteIdentity.uri.user}/${session.id}`);
                            console.log('Call establishing');
                            break;
                        // case SessionState.Established:
                        //     // session.bye();
                        //     console.log('Call established');
                        //     break;
                        // case SessionState.Terminated:
                        //     // session.bye();
                        //     navigate('/apps/iperx/utilisateurs/');
                        //     console.log('Call ended');
                        //     break;
                        default:
                            console.log('No active session to hang up');
                    }
                },
                onCallAnswered: (session) => {
                    updateSession(session);
                    setSessionTimer((timer) => ({
                        ...timer,
                        [session.id]: {
                            ...(timer[session.id] || {}),
                            answeredAt: new Date(),
                        },
                    }));
                },
                onCallHangup: (session) => {
                    updateSession(session);
                    setSessionTimer((timer) => ({
                        ...timer,
                        [session.id]: {
                            ...(timer[session.id] || {}),
                            hangupAt: new Date(),
                        },
                    }));
                    if (session instanceof Inviter) {
                        switch (session.state) {
                            case SessionState.Initial:
                            case SessionState.Establishing:
                                // session.cancel();
                                console.log('Call Establishing');
                                break;
                            case SessionState.Established:
                                // session.bye();
                                console.log('Call Established');
                                break;
                            case SessionState.Terminated:
                                // session.bye();
                                navigate('/apps/iperx/utilisateurs/');
                                console.log('Call ended');
                                break;
                            default:
                                console.log('No active session to hang up');
                        }
                    } else if (session instanceof Invitation) {
                        // session.bye();
                        console.log('Call ended');
                    }
                    // navigate('/apps/iperx/utilisateurs/');
                },
                onCallReceived: (session) => {
                    const caller = session?.remoteIdentity?.uri?.user ?? '';

                    if (caller && activeCalls[caller]) {
                        console.log(`Call from ${caller} is already active. Rejecting new call.`);
                        session.bye();
                    } else {
                        console.log("on recieve callll", session, session.id, session.remoteIdentity.uri.user)
                        setActiveCalls((prevActiveCalls: any) => ({
                            ...prevActiveCalls,
                            [caller]: session.remoteIdentity.uri.user,
                        }));
                        updateSession(session);
                        setSessionTimer((timer) => ({
                            ...timer,
                            [session.id]: {
                                ...(timer[session.id] || {}),
                                receivedAt: new Date(),
                            },
                        }));
                        if (session instanceof Invitation && session.state === SessionState.Establishing)
                            navigate(`/apps/iperx/utilisateurs/appel-waiting/${session.remoteIdentity.uri.user}/${session.id}`);
                    }
                },
                onMessageReceived: (message: Message) => {
                    console.log("recieve message", message);
                    // onMessageReceived(message);
                    try {
                        if (message.request.from.uri.user && message.request.body) {
                            chatDispatch({
                                type: RECEIVE_MESSAGE,
                                payload: {
                                    conversationId: Number(message.request.from.uri.user),
                                    message: message.request.body,
                                    attachments: undefined,
                                },
                            });
                        }
                        console.log(currentConversation)
                    } catch (error) {
                        console.error("Failed to dispatch message:", error);
                    }
                },
                onRegistered: () => {
                    setRegisterStatus(RegisterStatus.REGISTERED);
                },
                onUnregistered: () => {
                    setRegisterStatus(RegisterStatus.UNREGISTERED);
                },
                onServerConnect() {
                    setStatus(CONNECT_STATUS.CONNECTED);
                    sessionManager.register();
                },
                onServerDisconnect(error) {
                    console.error(
                        ErrorMessageLevel1.SIP_PROVIDER,
                        ErrorMessageLevel2.FAILED_CONNECT_SIP_USER,
                        error
                    );
                    setStatus(CONNECT_STATUS.DISCONNECTED);
                },
            },
        });
        setSessionManager(sessionManager);
        sessionManager.connect();
    }, []);





    return (
        <>
            <ProviderContext.Provider
                value={{
                    connectAndRegister,
                    sessionManager,
                    connectStatus,
                    registerStatus,
                    sessions,
                    sessionTimer,
                }}
            >
                {children}
            </ProviderContext.Provider>

            <audio ref={refAudioRemote} />
        </>
    );
};