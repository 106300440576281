import { ColumnDef } from "@tanstack/react-table";
import AdvanceTable from "components/base/AdvanceTable";
import { Link } from "react-router-dom";
import AdvanceTableFooter from "components/base/AdvanceTableFooter";
import Avatar from "components/base/Avatar";

import Badge from "components/base/Badge";
import { Users } from "../../../data/iper-x/ipbx/users";

export const usersTableColumns: ColumnDef<Users>[] = [
  //
  // {
  //   id: "user_status",
  //   accessorFn: ({ user_status }) => user_status.label,
  //   header: "Status",
  //   cell: ({ row: { original } }) => {
  //     const { user_status } = original;
  //     return (
  //       <Badge
  //         bg={user_status.type}
  //         variant="phoenix"
  //         iconPosition="end"
  //         className="fs-10"
  //         // icon={
  //         //   <FeatherIcon
  //         //       icon={user_status.icon}
  //         //       size={12.8}
  //         //       className="ms-1"
  //         //   />
  //         // }
  //       >
  //         {user_status.label}
  //       </Badge>
  //     );
  //   },
  //   meta: {
  //     headerProps: { style: { width: "15%" }, className: "pe-3" }
  //   }
  // },
  // {
  //   id: "user",
  //   accessorFn: ({ user }) => user.name,
  //   header: "Nom d’utilisateur",
  //   cell: ({ row: { original } }) => {
  //     const { name, avatar } = original.user;
  //     return (
  //       <Link
  //         to={`/apps/ipbx/utilisateurs/details/${name}`}
  //         className="d-flex align-items-center"
  //       >
  //         <Avatar src={avatar} size="m" />
  //         <p className="mb-0 ms-3 text-1100 fw-bold">{name}</p>
  //       </Link>
  //     );
  //   },
  //   meta: {
  //     headerProps: {
  //       style: { width: "18%", minWidth: 250 },
  //       className: "ps-8"
  //     },
  //     cellProps: { className: "ps-8 py-0 white-space-nowrap" }
  //   }
  // },


  {
    id: "user",
    accessorFn: ({ user }) => user.name,
    header: "Nom d’utilisateur",
    cell: ({ row: { original } }) => {
      const { name, avatar, status} = original.user;
      return (
        <Link
          to={`/apps/ipbx/utilisateurs/details/${name}`}
          className="d-flex align-items-center"
        >
          <Avatar src={avatar} size="m" className="me-3" status={status} />
          <p className="mb-0 ms-3 text-1100 fw-bold">{name}</p>
        </Link>
      );
    },
    meta: {
      headerProps: {
        style: { width: "20%", minWidth: 250 },
        className: "ps-8"
      },
      cellProps: { className: "ps-8 py-0 white-space-nowrap" }
    }
  },
  {
    id: "phone",
    accessorFn: ({ phone }) => phone.app,
    header: "Application mobile",
    cell: ({ row: { original } }) => {
      const { app } = original.phone;
      return (
        <img src={app}/>
      );
    },
    meta: {
      headerProps: {
        style: { width: "15%"},
      },
      cellProps: { className: "py-0 white-space-nowrap" }
    }
  },
  {
    id: "phone",
    accessorFn: ({ phone }) => phone.app,
    header: "Softphone",
    cell: ({ row: { original } }) => {
      const { softphone } = original.phone;
      return (
        <img src={softphone}/>
      );
    },
    meta: {
      headerProps: {
        style: { width: "15%"},
      },
      cellProps: { className: "py-0 white-space-nowrap" }
    }
  },
  {
    accessorKey: "devices",
    header: "Téléphone",
    meta: {
      headerProps: { style: { width: "15%" } },
      cellProps: { className: "text-900 fs-9" }
    }
  },
  {
    accessorKey: "email",
    header: "Email",
    meta: {
      headerProps: { style: { width: "15%" } },
      cellProps: { className: "text-900 fs-9" }
    }
  },
  {
    accessorKey: "mobile",
    header: "Mobile",
    meta: {
      headerProps: { style: { width: "15%" } },
      cellProps: { className: "text-900 fs-9" }
    }
  },
  {
    accessorKey: "calling_id",
    header: "Fixe",
    meta: {
      headerProps: { style: { width: "15%" } },
      cellProps: { className: "text-900 fs-9" }
    }
  },
  
];

const UsersTable = () => {
  return (
    <div>
      <AdvanceTable tableProps={{ className: "phoenix-table fs-9" }} />
      <AdvanceTableFooter pagination />
    </div>
  );
};

export default UsersTable;
