import React, { Dispatch, SetStateAction, useState } from 'react';
import { faArrowLeft, faPhoneAlt } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import { Card, Row, Col, Button, Form } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { IperxDropdowCallingTaplabels } from 'data/social/IperxDropdowData';

interface IperxCallingTapToolsProps {
  callNumber: string;
  setCallNumber: Dispatch<SetStateAction<string>>;
  className?: string;
}

const IperxCallingTapTools = ({ className, callNumber, setCallNumber }: IperxCallingTapToolsProps) => {

  const handleButtonClick = (text: string) => {
    setCallNumber(prevText => prevText + text);
  };

  const handleBackspaceClick = () => {
    setCallNumber(prevText => prevText.slice(0, -1));
  };

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setCallNumber(event.target.value);
  };

  return (
    <Card className='justify-content-center align-items-center' style={{ height: '730px' }}>
      <div className="mb-4">
        <Row className="g-3 ms-9">
          <Col>
            <Form>
              <Row className="justify-content-center">
                <Col className="d-flex align-items-center justify-content-center">
                  <Form.Control
                    className="w-700"
                    style={{ width: '315px' }}
                    type="text"
                    value={callNumber}
                    onChange={handleInputChange}
                  />
                  <Button variant="link" onClick={handleBackspaceClick}>
                    <FontAwesomeIcon icon={faArrowLeft} className='fs-8' />
                  </Button>
                </Col>
              </Row>
            </Form>
          </Col>
        </Row>
      </div>
      <Row className={classNames(className, 'g-0 justify-content-center')} style={{ width: '45%' }}>
        {IperxDropdowCallingTaplabels.map((item, index) => (
          <Col
            key={index}
            xs={4}
            className={classNames('py-2 text-center border-1', {
              'border-end': (index + 1) % 3 !== 0,
              'border-bottom': index < 9
            })}
          >
            <Button
              as={Link}
              to="#!"
              variant="link"
              className={classNames(
                'fs-4 text-800 hover-primary fw-semi-bold d-flex flex-column align-items-center',
                {
                  'mt-2': index > 2,
                  'text-uppercase': index !== 0 && index !== 10 && index !== 11
                }
              )}
              onClick={() => handleButtonClick(item.label)}
            >
              {item.label}
              <div className="fs-8">{item.letter}</div>
            </Button>
          </Col>
        ))}
      </Row>
    </Card>
  );
};

export default IperxCallingTapTools;
